import "./locale/i18n";

import * as Sentry from "@sentry/react";
import { ApolloProvider } from "@apollo/client";
import React from "react";
import * as ReactDOMClient from "react-dom/client";
import amplitude from "amplitude-js";
import { RouterProvider } from "react-router-dom";
import Snowfall from "react-snowfall";

import packageJson from "../package.json";

import { isProduction } from "./Functions/UserAgentUtil";
import { GlobalStyle } from "./core/styles";
import client from "./core/apollo/client";

// @onthelook/ui의 스타일에 덮어쓰기를 하기 위해 아래와 같이 import 해야 합니다.
import "@onthelook/ui/styles.css";
import "./index.css";

import {
  AmplitudeProvider,
  GoogleAnalyticsProvider,
} from "core/context/third-party";
import { getWebStorage } from "core/storage";
import { ResizeImageProvider } from "core/context";
import { UUIDProvider } from "core/context/uuid-provider";
import { AuthProvider } from "core/context/auth-provider";
import router from "router";
import { AppDownLoadProvider } from "core/context/app-download-provider";

if (isProduction()) {
  Sentry.init({
    dsn: "https://4bd1dbcef8fc48dabef1e1cf068a86f2@o649326.ingest.sentry.io/5760206",
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 0.2,
    release: `v${packageJson.version}`,
    environment: import.meta.env.VITE_API_PROFILE as string,
  });
}

const container = document.getElementById("root") as HTMLElement;
const root = ReactDOMClient.createRoot(container);
const AMPLITUDE_API_KEY = import.meta.env.VITE_AMPLITUDE_API_KEY as string;
const storage = getWebStorage();

// IMPORTANT: 서로간의 의존성이 있기 때문에 순서 중요합니다. 변경 시 의존성에 문제가 없는지 확인 부탁드립니다.
root.render(
  <ApolloProvider client={client}>
    <UUIDProvider>
      <AmplitudeProvider
        amplitudeInstance={amplitude.getInstance()}
        apiKey={AMPLITUDE_API_KEY}
        config={{
          includeGclid: true,
          includeReferrer: true,
          includeUtm: true,
        }}
        callback={(client) => {
          storage.setItem("amplitudeId", client?.options?.deviceId || "");
        }}
      >
        <GoogleAnalyticsProvider
          gtmId={import.meta.env.VITE_GOOGLE_GTM_ID as string}
          gaId={import.meta.env.VITE_GOOGLE_GA4_ID as string}
        >
          <ResizeImageProvider>
            <AuthProvider>
              <AppDownLoadProvider>
                <GlobalStyle />
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 9999,
                    pointerEvents: "none",
                  }}
                >
                  <Snowfall
                    snowflakeCount={50}
                    speed={[0, 1.5]}
                    wind={[-0.5, 0.5]}
                    radius={[0.5, 4]}
                    color="#dee4fd"
                    style={{ zIndex: 9999 }}
                  />
                </div>
                <RouterProvider router={router} />
              </AppDownLoadProvider>
            </AuthProvider>
          </ResizeImageProvider>
        </GoogleAnalyticsProvider>
      </AmplitudeProvider>
    </UUIDProvider>
  </ApolloProvider>
);
